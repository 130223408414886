import Vue from 'vue';

const INITIAL_STATE = () => ({
  fetchError: null,
  sendErrors: {},
  messages: {},
  channels: [],
  subscriptions: {},
  connected: false
});

export const state = INITIAL_STATE;

export const mutations = {
  RESET (state) {
    Object.assign(state, INITIAL_STATE());
  },
  SET_FETCH_ERROR (state, error) {
    state.fetchError = error;
  },
  SET_SEND_ERROR (state, {principal, errorMessage}) {
    state.sendErrors = {
      ...state.sendErrors,
      [principal]: errorMessage,
    }
  },
  SET_MESSAGES (state, messages) {
    state.messages = messages;
  },
  SET_CHANNELS (state, channels) {
    state.channels = channels;
  },
  SET_CHANNEL (state, channel) {
    const channels = state.channels.filter((c) => c.id !== channel.id);
    state.channels = [channel, ...channels];
  },
  SET_MESSAGES_FOR_CHANNEL (state, { channelId, messages, hasEarlierMessages=state.messages[channelId]?.hasEarlierMessages }) {
    Vue.set(state.messages, channelId, {messages, hasEarlierMessages});
  },
  ADD_MESSAGE (state, { message }) {
    const { channelId } = message;
    const { messages = [], hasEarlierMessages } = state.messages[channelId] || {};
    messages.unshift(message);
    Vue.set(state.messages, channelId, { messages, hasEarlierMessages });
  },
  UPDATE_MESSAGE_STATUS (state, { messageId, channelId, status }) {
    if (!messageId || !status) return;

    const message = channelId
        // Search in specified channel
        ? state.messages[channelId]?.messages?.find(m => m.id === messageId)
        // Search across all channels
        : Object.values(state.messages).flatMap(c => c.messages).find(m => m.id === messageId);

    if (message) {
      message.status = status;
      const channel = state.messages[message.channelId]
      Vue.set(state.messages, channelId, channel);
    }
  },
  UPDATE_MESSAGE_STATUS_BY_DATE (state, { userId, principal, date, status }) {
    if (!userId || !principal || !date || !status) return;

    const channel = state.channels.find(c => c.principal === principal);
    if (!channel) return;

    const { messages, hasEarlierMessages } = state.messages[channel.id];
    if (!messages) return;

    let changed = false;
    messages.forEach(m => {
      console.log(m);
      if (
          m.principal === principal &&
          m.createdDate < date &&
          m.isMyMessage === false &&
          m.status !== status
      ) {
        m.status = status;
        changed = true;
      }
    })
    if (changed) Vue.set(state.messages, channel.id, { messages, hasEarlierMessages });
  },
  ADD_SUBSCRIPTION (state, { principal, unsubscribe }) {
    state.subscriptions[principal] = unsubscribe;
  },
  REMOVE_SUBSCRIPTION (state, { principal }) {
    delete state.subscriptions[principal];
  },
  CONNECTED (state) {
    state.connected = true
  },
  DISCONNECTED (state) {
    state.connected = false
  },
};

export const actions = {
  initMessagingModule ({ commit, dispatch, state }) {

    this.$chat.onConnect(() => {
      console.log('Chat: connected');
      // Subscribe for channels updates
      this.$chat.onChannelUpdated((channel) => commit('SET_CHANNEL', channel));

      // Query channels list
      this.$chat.getChannels()
          .then(channels => commit('SET_CHANNELS', channels))
          .catch(error => commit("snackbar/error", `Couldn't get channels list (${error.message})`, { root: true })
      );

      // Restore subscriptions after reconnect
      for (const principal in state.subscriptions) {
        dispatch('subscribeToChannel', principal);
      }

      commit('CONNECTED');
    })

    this.$chat.onDisconnect(() => {
      console.log('Chat: disconnected');
      commit('DISCONNECTED');
    });

    this.$chat.activate();
  },

  teardownMessagingModule ({ commit }) {
    this.$chat.deactivate();
    commit('RESET');
  },

  async fetchMessagesForChannel ( { state, commit, getters }, { channelId, additional }) {
    if (!channelId) {
      throw new Error('Channel id required');
    }
    try {
      const existingMessages = getters.getMessagesByChannelId(channelId);
      const earliestCreatedDate = additional ? getters.earliestCreatedByChannelId(channelId) : undefined;

      const { messages: newMessages, hasEarlierMessages } = await this.$chat.getMessages(channelId, earliestCreatedDate)
      const messages = additional ? [...existingMessages, ...newMessages] : newMessages;

      commit('SET_MESSAGES_FOR_CHANNEL', { channelId, messages, hasEarlierMessages });
      commit('SET_FETCH_ERROR', null);

      return newMessages;
    } catch (err) {
      commit('SET_FETCH_ERROR', { message: 'Error retrieving messages' });
    }
  },

  async unsubscribeFromChannel ({ state, commit }, principal) {
    const unsubscribe = state.subscriptions[principal];
    if (unsubscribe) {
      commit('REMOVE_SUBSCRIPTION', { principal });
      unsubscribe();
    }
  },

  async subscribeToChannel ({ state, commit, dispatch }, principal) {
    const channel = state.channels.find(c => c.principal === principal);
    const { unsubscribe } = this.$chat.onChannelMessage(
        principal,
        (message) => commit('ADD_MESSAGE', { message }),
        (status) => commit('UPDATE_MESSAGE_STATUS', { ...status }),
        (status) => commit('UPDATE_MESSAGE_STATUS_BY_DATE', { ...status })
    );
    commit('ADD_SUBSCRIPTION', { principal, unsubscribe });
    if (channel) dispatch('fetchMessagesForChannel', { channelId: channel.id, additional: false });
    return channel;
  },

  async sendMessage ({ commit, dispatch}, {principal, message, medium}) {
    try {
      const sentMessage = await this.$chat.sendMessage(medium, principal, message)
      commit('SET_SEND_ERROR', {principal, errorMessage: null});
      return sentMessage;
    } catch (err) {
      if (err.code === 'UniqueConstraintChannelPhoneNumbers') {
        commit('SET_SEND_ERROR', {principal, errorMessage: 'This phone number is already being used by another patient.'})
      } else {
        console.log('Error sending message', err)
        commit('SET_SEND_ERROR', {principal, errorMessage: 'Error sending message' });
      }
    }
  },
};

export const getters = {
  getMessagesByChannelId: (state) => (channelId) => {
    return state.messages[channelId] && state.messages[channelId].messages || [];
  },
  earliestCreatedByChannelId: (_, getters) => (channelId) => {
    const channelMessages = getters.getMessagesByChannelId(channelId);
    return channelMessages.length ? channelMessages[channelMessages.length-1].createdDate : null;
  },
  hasEarlierMessagesByChannelId: (state) => (channelId) => {
    return state.messages[channelId] && state.messages[channelId].hasEarlierMessages;
  },
  unreadChannelsCount: (state, _, rootState, rootGetters) => {
    const ownerId = rootGetters['portal/userId'];
    return state.channels.filter(c => c.unreadMessages > 0 && c.owner === ownerId).length
  },
  isConnected: (state) => state.connected,
  isDisconnected: (state) => !state.connected,
};

